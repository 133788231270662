import React, { createContext, useState, useContext, ReactNode } from "react";

type ModalType = "package" | "bfcm";

interface ModalState {
  type: ModalType;
  props: ModalPropsMap[ModalType];
}

interface ModalContextType {
  openModal: <T extends ModalType>(modalType: T, modalProps: ModalPropsMap[T]) => void;
  closeModal: (modalType: ModalType) => void;
  modals: ModalState[];
}

interface ModalPropsMap {
  package: { email: string };
  bfcm: undefined;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<ModalState[]>([]);

  const openModal = <T extends ModalType>(type: T, props: ModalPropsMap[T]) => {
    setModals((prevModals) => [...prevModals, { type, props: props as ModalPropsMap[ModalType] }]);
  };

  const closeModal = (modalType: ModalType) => {
    setModals((prevModals) => prevModals.filter((modal) => modal.type !== modalType));
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, modals }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return {
    ...context,
    openPackageModal: (email: string) => context.openModal("package", { email }),
    openBfcmModal: () => context.openModal("bfcm", undefined),
  };
};
