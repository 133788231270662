import React from "react";
import { useModalContext } from "@/lib/contexts/ModalContext";

const ModalManager: React.FC = () => {
  const { modals, closeModal } = useModalContext();

  return (
    <>
      {modals.map((modal, index) => {
        switch (modal.type) {
          case "package":
            return null;
        }
      })}
    </>
  );
};

export default ModalManager;
