let isMounted = false;
let cachedUtmParams: URLSearchParams | null = null;

// New initialization function
export function initializeUtm() {
  if (typeof window === "undefined") return;
  if (isMounted) return;

  try {
    const currentUrl = new URL(window.location.href);
    const utmParams = new URLSearchParams();

    // List of UTM parameters to preserve
    const utmKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

    // Copy existing UTM parameters
    utmKeys.forEach((key) => {
      const value = currentUrl.searchParams.get(key);
      if (value) utmParams.append(key, value);
    });

    // Cache the params
    cachedUtmParams = utmParams;
    isMounted = true;
  } catch (e) {
    console.error("Failed to initialize UTM params:", e);
    cachedUtmParams = new URLSearchParams();
    isMounted = true;
  }
}

export function getUtmParams(): URLSearchParams {
  if (!isMounted || !cachedUtmParams) {
    return new URLSearchParams();
  }
  return cachedUtmParams;
}

export function appendUtmParams(url: string): string {
  if (!isMounted || !cachedUtmParams) {
    return url;
  }

  try {
    const targetUrl = new URL(url);

    // Append UTM parameters if they exist
    cachedUtmParams.forEach((value, key) => {
      targetUrl.searchParams.set(key, value);
    });

    return targetUrl.toString();
  } catch (e) {
    // If URL parsing fails, return original URL
    return url;
  }
}
